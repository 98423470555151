<template>
  <van-popup class="diy-popup" round v-model="show">
    <div class="wallet-box">
      <div class="title font-m">{{ $i18n.t("com_wal_tips") }} <br />SuperRare</div>
      <div class="wallet-list">
        <div class="wallet-item">
          <div class="avatat">
            <img src="../assets/images/metamask.svg" alt="" />
          </div>
          <div class="name">MetaMask</div>
        </div>
        <div class="wallet-item">
          <div class="avatat">
            <img src="../assets/images/trust.svg" alt="" />
          </div>
          <div class="name">Trust Wallet</div>
        </div>
        <div class="wallet-item">
          <div class="avatat">
            <img src="../assets/images/icon-link.svg" alt="" />
          </div>
          <div class="name">
            <input type="text" :placeholder="cusPlaceholder" />
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cusPlaceholder() {
      return this.$i18n.t("com_enter_place") || "";
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
.diy-popup {
  width: 80%;
  overflow: hidden;
  .wallet-box {
    padding: 0.3rem 0.4rem;
    .title {
      text-align: left;
      font-size: 0.32rem;
      color: #333;
      line-height: 0.48rem;
    }
    .wallet-list {
      .wallet-item {
        display: flex;
        padding: 0.2rem 0.2rem;
        border-radius: 0.2rem;
        border: 1px solid #eee;
        margin: 0.3rem 0;
        .avatat {
          height: 0.64rem;
          margin-right: 0.3rem;
          img {
            width: 0.64rem;
            height: 0.64rem;
          }
        }
        .name {
          font-size: 0.28rem;
          color: #666;
          line-height: 0.64rem;
          input {
            border: none;
            outline: none;
            height: 0.64rem;
          }
        }
      }
    }
  }
}
</style>
